import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

const ContentfulEmbeddedAsset = ({ contentfulId }) => {
  const data = useStaticQuery(graphql`
    query QueryAllAssetsByContentfulId {
      allContentfulAsset {
        nodes {
          contentful_id
          file {
            url
          }
          title
        }
      }
    }
  `);

  const asset = data.allContentfulAsset.nodes.find(
    ({ contentful_id }) => contentful_id === contentfulId
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-8 col-lg-7 col-xl-6">
        <img
          className="img-thumbnail"
          src={'https:' + asset.file.url}
          alt={asset.title}
        />
      </div>
    </div>
  );
};

ContentfulEmbeddedAsset.propTypes = {
  contentfulId: PropTypes.string.isRequired,
};

export default ContentfulEmbeddedAsset;
