import React from 'react';
import { Link, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import RichTextOptions from '../utils/richTextOptions';
import { login, isUserAuthenticated } from '../utils/auth';

// TODO: use pageContext param and get previous/next blog posts
// https://github.com/gatsbyjs/gatsby-starter-blog/blob/master/src/templates/blog-post.js#L12
class PostTemplate extends React.Component {
  constructor(props) {
    const { data } = props;
    super(props);

    this.state = {
      data,
      isLoading: true,
      isAuthenticated: false,
    };
  }

  async componentDidMount() {
    const isAuthenticated = await isUserAuthenticated();
    this.setState({ isAuthenticated, isLoading: false });
  }

  render() {
    const { data, isLoading, isAuthenticated } = this.state;
    if (isLoading) {
      return <p>Loading post...</p>;
    }

    if (!isAuthenticated) {
      login();
      return <p>Redirecting to login...</p>;
    }

    const post = data.contentfulBlogPost;
    const richTextRendered = documentToReactComponents(
      post.blogPostContent.json,
      RichTextOptions
    );

    const props = {
      title: `${post.title} | The Cao Farm`,
      jumbotronTitle: post.title,
      jumbotronCaption: post.twoSentenceSummary,
    };

    return (
      <Layout {...props}>
        <div className="mb-4">
          <div>
            <h4>Date: {post.date}</h4>
          </div>
          <div>
            <h4>
              Author:{' '}
              <Link to={post.author.fields.fullSlug}>{post.author.name}</Link>
            </h4>
          </div>
        </div>
        {richTextRendered}
      </Layout>
    );
  }
}

export default PostTemplate;

export const PostQuery = graphql`
  query BlogPostBySlug($contentfulId: String!) {
    contentfulBlogPost(contentful_id: { eq: $contentfulId }) {
      title
      date(formatString: "MMMM Do, YYYY")
      author {
        name
        fields {
          fullSlug
        }
      }
      twoSentenceSummary
      blogPostContent {
        json
      }
    }
  }
`;
