import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import BlogPostCard from './blogPostCard';
import PersonCard from './personCard';

const ContentfulEmbeddedEntry = ({ contentfulId }) => {
  const data = useStaticQuery(graphql`
    query QueryAllEntriesByContentfulId {
      allContentfulBlogPost {
        nodes {
          contentful_id
          date(formatString: "DD MMMM, YYYY")
          mainImage {
            title
            file {
              url
            }
          }
          title
          twoSentenceSummary
          fields {
            fullSlug
          }
        }
      }
      allContentfulPerson {
        nodes {
          contentful_id
          name
          profilePicture {
            file {
              url
            }
          }
          fields {
            fullSlug
          }
        }
      }
    }
  `);
  const blogPost = data.allContentfulBlogPost.nodes.find(
    ({ contentful_id }) => contentful_id === contentfulId
  );
  const person = data.allContentfulPerson.nodes.find(
    ({ contentful_id }) => contentful_id === contentfulId
  );
  let props = {};
  if (blogPost) {
    props = {
      imageTitle: blogPost.mainImage.title,
      imageUrl: blogPost.mainImage.file.url,
      postDate: blogPost.date,
      postSlug: blogPost.fields.fullSlug,
      postTitle: blogPost.title,
      postTwoSentenceSummary: blogPost.twoSentenceSummary,
      linkCaption: 'Go to linked post',
    };
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-7 col-xl-6">
          <BlogPostCard {...props} />
        </div>
      </div>
    );
  } else if (person) {
    props = {
      name: person.name,
      personPageUrl: person.fields.fullSlug,
      profilePictureUrl: person.profilePicture.file.url,
      linkCaption: `Go to ${person.name}'s linked page`,
    };
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-7 col-xl-6">
          <PersonCard {...props} />
        </div>
      </div>
    );
  }
};

ContentfulEmbeddedEntry.propTypes = {
  contentfulId: PropTypes.string.isRequired,
};

export default ContentfulEmbeddedEntry;
