import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const PersonCard = ({
  name,
  personPageUrl,
  profilePictureUrl,
  linkCaption,
}) => {
  return (
    <div className="card">
      <Link to={personPageUrl}>
        <img
          src={'https:' + profilePictureUrl}
          className="card-img-top"
          alt={`${name}'s profile`}
        />
      </Link>
      <div className="card-body">
        <h5 className="card-title">{name}</h5>
        <Link className="card-link" to={personPageUrl}>
          <h6 className="card-subtitle mb-2">
            {linkCaption ? linkCaption : `Go to ${name}'s page`}
          </h6>
        </Link>
      </div>
    </div>
  );
};

PersonCard.propTypes = {
  name: PropTypes.string.isRequired,
  personPageUrl: PropTypes.string.isRequired,
  profilePictureUrl: PropTypes.string.isRequired,
  linkCaption: PropTypes.string,
};

export default PersonCard;
