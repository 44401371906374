import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import { logout } from '../utils/auth';
import logo from '../img/caoFarmProjectLogo_circle.png';

import styles from './navbar.module.scss';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleInNavbar {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <div className="container-fluid navbar-dark bg-dark">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <Link to="/app" className="navbar-brand">
              <img
                src={logo}
                width="35"
                height="35"
                className="d-inline-block align-top mr-2"
                alt=""
                loading="lazy"
              />
              {data.site.siteMetadata.title}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* NOTE: Make sure this mirrors the App.js Router */}
              <div className="navbar-nav mr-auto">
                <Link to="/app/blog" className="nav-item nav-link">
                  Blog
                </Link>
                <Link to="/app/family-members" className="nav-item nav-link">
                  Family Members
                </Link>
                <Link to="/app/pictures" className="nav-item nav-link">
                  Pictures
                </Link>
              </div>
              <a
                className="nav-item nav-link"
                href="#logout"
                onClick={(event) => {
                  logout();
                  event.preventDefault();
                }}
              >
                Log Out
              </a>
            </div>
          </nav>
        </div>
      </div>
      <div className={styles.navbarColorbar}></div>
    </>
  );
};

export default Navbar;
