import React from 'react';
import { Helmet } from 'react-helmet';

const HtmlHeader = () => (
  <Helmet>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    {/* DON'T REMOVE; IT KEEPS AWAY WEB CRAWLERS */}
    <meta name="robots" content="noindex" />
  </Helmet>
);

export default HtmlHeader;
