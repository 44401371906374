import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query GatsbyEnv {
      site {
        siteMetadata {
          environment
        }
      }
    }
  `);

  return (
    <footer className="mt-5">
      You are in a PROTECTED{' '}
      <strong>{data.site.siteMetadata.environment}</strong> environment.
      <p>
        © {new Date().getFullYear()}, Built with{' '}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </p>
    </footer>
  );
};

export default Footer;
