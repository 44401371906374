import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Navbar from './navbar';
import Footer from './footer';
import HtmlHeader from './htmlHeader';
import Jumbotron from './jumbotron';

const Layout = ({ children, jumbotronCaption, jumbotronTitle, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <HtmlHeader />

    <Navbar />
    <Jumbotron
      jumbotronTitle={jumbotronTitle}
      jumbotronCaption={jumbotronCaption}
    />

    <div className="container">
      <main>{children}</main>
      <Footer />
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  jumbotronTitle: PropTypes.string.isRequired,
  jumbotronCaption: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;
