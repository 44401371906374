import React from 'react';
import PropTypes from 'prop-types';

const Jumbotron = ({ jumbotronCaption, jumbotronTitle }) => (
  <div className="container-fluid bg-light">
    <div className="jumbotron jumbotron-fluid bg-light">
      <header className="container">
        <h1 className="display-4">{jumbotronTitle}</h1>
        <p className="lead">{jumbotronCaption}</p>
      </header>
    </div>
  </div>
);

Jumbotron.propTypes = {
  jumbotronCaption: PropTypes.string.isRequired,
  jumbotronTitle: PropTypes.string.isRequired,
};

export default Jumbotron;
