import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const BlogPostCard = ({
  imageTitle,
  imageUrl,
  postDate,
  postSlug,
  postTitle,
  postTwoSentenceSummary,
  linkCaption,
}) => {
  return (
    <div className="card">
      <Link to={postSlug}>
        <img
          src={'https:' + imageUrl}
          className="card-img-top"
          alt={imageTitle}
        />
      </Link>
      <div className="card-body">
        <h5 className="card-title">{postTitle}</h5>
        <h6 className="card-subtitle mb-2 text-muted">{postDate}</h6>
        <p className="card-text">{postTwoSentenceSummary}</p>
        <Link to={postSlug} className="card-link">
          {linkCaption ? linkCaption : 'Go to post'}
        </Link>
      </div>
    </div>
  );
};

BlogPostCard.propTypes = {
  imageTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  postDate: PropTypes.string.isRequired,
  postSlug: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postTwoSentenceSummary: PropTypes.string.isRequired,
  linkCaption: PropTypes.string,
};

export default BlogPostCard;
