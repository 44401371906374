import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import ContentfulEmbeddedAsset from '../components/contentfulEmbeddedAsset';
import ContentfulEmbeddedEntry from '../components/contentfulEmbeddedEntry';

const RichTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { data } = node;
      const contentfulId = data.target.sys.contentful_id;
      return <ContentfulEmbeddedAsset contentfulId={contentfulId} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      const contentfulId = data.target.sys.contentful_id;
      return <ContentfulEmbeddedEntry contentfulId={contentfulId} />;
    },
    [BLOCKS.QUOTE]: (node) => {
      const paragraphs = node.content.map((contentItem) => {
        return contentItem.nodeType === 'paragraph'
          ? contentItem.content[0].value
          : '';
      });
      return (
        <>
          {paragraphs.map((paragraph) => (
            <blockquote className="blockquote text-center" key={paragraph}>
              {paragraph}
            </blockquote>
          ))}
        </>
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      const { data } = node;
      const HTTPS = 'https://';
      const HTTP = 'http://';
      let url = data.uri;
      if (!(url.startsWith(HTTPS) || url.startsWith(HTTP))) {
        url = HTTPS + url;
      }
      return <a href={url}>{node.content[0].value}</a>;
    },
  },
};

export default RichTextOptions;
